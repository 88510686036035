import React, {useEffect, useState} from 'react';
import DashboardLayout from "../../components/DashboardLayout";
import {fetchAPI} from "../../config/fetch";
import Table from "../../components/table/Table";
import TableHead from "../../components/table/TableHead";
import TableBody from "../../components/table/TableBody";
import Accordion from "../../components/accordion/Accordion";
import AccordionItem from "../../components/accordion/AccordionItem";
import AccordionHeader from "../../components/accordion/AccordionHeader";
import AccordionBody from "../../components/accordion/AccordionBody";
import Container from "../../components/Container";
import {GroupProps, Size} from "../../props/common";
import Spacer from "../../components/Spacer";
import Title from "../../components/Title";
import Flex, {FlexItem} from "../../components/Flex";
import Nav from "../../components/Nav";

const IndividualGroups = () => {
    const [groups, setGroups] = useState<any>({
        open: [],
        vet: [],
        wom: [],
        u20: [],
        u16: [],
        u12: []
    });

    useEffect(() => {
        fetchAPI('/groups/individual/Major2024')
            .then(res => {
                if (Object.keys(res).length) {
                    setGroups({
                        open: res.filter((group: GroupProps) => group.category === "OPEN"),
                        vet: res.filter((group: GroupProps) => group.category === "VET"),
                        wom: res.filter((group: GroupProps) => group.category === "WOMAN"),
                        u20: res.filter((group: GroupProps) => group.category === "U20"),
                        u16: res.filter((group: GroupProps) => group.category === "U16"),
                        u12: res.filter((group: GroupProps) => group.category === "U12")
                    })
                }
            })
            .catch(err => {
                console.log(err);
                alert(err);
            })
    }, []);

    return (
        <DashboardLayout currentPage="groups" sidebar={false}>
            {
                <Container padding={Size.LEVEL_TWO}>
                    <Nav currentPage="groups" parent="individual"/>
                    {Object.keys(groups).length &&
                        <>
                            <Spacer size={Size.LEVEL_FOUR}/>
                            <Accordion>
                                {
                                    Object.keys(groups).map((category, index) => {
                                        return <AccordionItem key={index} className={index === 0 ? 'is-active' : ''}>
                                            <AccordionHeader>{category.toUpperCase()}</AccordionHeader>
                                            <AccordionBody>
                                                <Flex multiline alignItems="flex-start">
                                                    {
                                                        (groups[category] && groups[category].length) &&
                                                        groups[category].map((group: GroupProps, index: number) => {
                                                            return <FlexItem key={index} width="45%">
                                                                <Table>
                                                                    <TableHead>
                                                                        <tr>
                                                                            <td style={{minWidth: 175}} colSpan={2}>
                                                                                <Title size={Size.LEVEL_TWO}
                                                                                       marginBottom={0}>
                                                                                    Group {group.group}
                                                                                </Title>
                                                                            </td>
                                                                            <td>Pts</td>
                                                                            <td>P</td>
                                                                            <td>W</td>
                                                                            <td>D</td>
                                                                            <td>L</td>
                                                                            <td>GF</td>
                                                                            <td>GA</td>
                                                                            <td>GD</td>
                                                                        </tr>
                                                                    </TableHead>
                                                                    <TableBody>
                                                                        {
                                                                            group.players.map((player: any, index: number) => {
                                                                                return <tr key={index}>
                                                                                    <td style={{width: "50px"}}>{index + 1}.</td>
                                                                                    <td>{player.playerName}</td>
                                                                                    <td style={{fontWeight: 'bold'}}>{player.playerPoints}</td>
                                                                                    <td>{player.wins + player.draws + player.loses}</td>
                                                                                    <td>{player.wins}</td>
                                                                                    <td>{player.draws}</td>
                                                                                    <td>{player.loses}</td>
                                                                                    <td>{player.gf}</td>
                                                                                    <td>{player.ga}</td>
                                                                                    <td>{player.gd}</td>
                                                                                </tr>
                                                                            })
                                                                        }
                                                                    </TableBody>
                                                                </Table>
                                                            </FlexItem>
                                                        })
                                                    }
                                                </Flex>
                                            </AccordionBody>
                                        </AccordionItem>
                                    })
                                }
                            </Accordion>
                        </>
                    }
                </Container>
            }
        </DashboardLayout>
    )
}

export default IndividualGroups;
