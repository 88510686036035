import React, {useEffect, useState} from 'react';
import Container from "../../../../components/Container";
import Flex, {FlexItem} from "../../../../components/Flex";
import Tag from "../../../../components/Tag";
import Title from "../../../../components/Title";
import {Size} from "../../../../props/common";
import io, {Socket} from "socket.io-client";
import {DefaultEventsMap} from "@socket.io/component-emitter";
import env from "../../../../config/env";
import {_sortMatchesByTableNumber, MatchProps, PlayerProps, teamEmptyMatch, TeamMatchProps} from "../../matchProps";
import {fetchAPI} from "../../../../config/fetch";
import Spacer from "../../../../components/Spacer";
import {green} from "../../../../config/style";
import Divider from "../../../../components/Divider";
import ftcissy from "../../../../assets/logos/FTC issy Rond.png";
import {getBestMatchingPlayer} from "../../../../utils/getBestMatchingPlayer";

let socket: Socket<DefaultEventsMap, DefaultEventsMap> = io(env.SOCKET, {transports: ['websocket']});

const _computeSingleMatchGoals = (allSingleMatches: MatchProps[]) => {
    let teamOneGoals = 0;
    let teamTwoGoals = 0;

    allSingleMatches.forEach((singleMatch: MatchProps) => {
        teamOneGoals += singleMatch.score_player_one;
        teamTwoGoals += singleMatch.score_player_two;
    });

    return {teamOneGoals, teamTwoGoals};
};

const _playerLastnameUppercase = (playerName: string): string => {
    const playerNameSplitted: string[] = playerName.split(' ');
    const playerLastnameUppercased = playerNameSplitted[playerNameSplitted.length - 1].toUpperCase();
    playerNameSplitted.pop();
    return `${playerNameSplitted.join(' ')} ${playerLastnameUppercased}`;
};

const _generateOnePlayerTag = (match: TeamMatchProps, index: number) => {
    const singleMatch: MatchProps = match.all_single_matches[index];
    const playerOne: string = getBestMatchingPlayer(singleMatch.player_one.fullname, singleMatch.substitute_player_one);
    const playerTwo: string = getBestMatchingPlayer(singleMatch.player_two.fullname, singleMatch.substitute_player_two);

    return <Flex alignItems="flex-start" key={match.id + Math.random()}>
        <FlexItem width="5vw">
            <Tag fontSize={Size.LEVEL_THREE}>{singleMatch.table_number}</Tag>
        </FlexItem>
        <FlexItem width="42.5vw">
            <Tag fontSize={Size.LEVEL_FOUR} backgroundFlag={singleMatch.player_one?.country}
                 clubLogo={singleMatch.player_one?.club} boxShadow
                 small star={singleMatch.player_one?.star}>
                {_playerLastnameUppercase(playerOne)}
            </Tag>
        </FlexItem>
        <FlexItem width="5vw">
            <Tag fontSize={Size.LEVEL_FOUR}
                 borderColor={singleMatch.score_player_one > singleMatch.score_player_two ? green : 'transparent'}>
                {singleMatch.score_player_one}
            </Tag>
        </FlexItem>
        <FlexItem width="5vw">
            <Tag fontSize={Size.LEVEL_FOUR}
                 borderColor={singleMatch.score_player_one < singleMatch.score_player_two ? green : 'transparent'}>
                {singleMatch.score_player_two}
            </Tag>
        </FlexItem>
        <FlexItem width="42.5vw">
            <Tag fontSize={Size.LEVEL_FOUR} backgroundFlag={singleMatch.player_two?.country}
                 clubLogo={singleMatch.player_two?.club} boxShadow
                 small clubLogoPosition='right' star={singleMatch.player_two?.star}>
                {_playerLastnameUppercase(playerTwo)}
            </Tag>
        </FlexItem>
    </Flex>;
};

const _generateSubstitutes = (match: TeamMatchProps, index: number): React.ReactElement => {
    const teamOnePlayers = match.team_one.all_players.map((player: PlayerProps) => player);
    const teamTwoPlayers = match.team_two.all_players.map((player: PlayerProps) => player);
    const teamOneBusyPlayers = match.all_single_matches.map((match: MatchProps) => match.player_one.fullname);
    const teamTwoBusyPlayers = match.all_single_matches.map((match: MatchProps) => match.player_two.fullname);

    const teamOneSubstitutes = teamOnePlayers.filter((player: PlayerProps) => !teamOneBusyPlayers.includes(player.fullname));
    const teamTwoSubstitutes = teamTwoPlayers.filter((player: PlayerProps) => !teamTwoBusyPlayers.includes(player.fullname));

    return <div key={Math.random()}>
        {
            index === 0 &&
            <>
                <Spacer size={Size.LEVEL_TWO}/>
                <Flex>
                    <FlexItem width="5vw"/>
                    <FlexItem width="42.5vw">
                        <Flex justifyContent="center">
                            <Divider direction="horizontal" color={green} size="50%" margin={Size.LEVEL_FIVE}/>
                        </Flex>
                    </FlexItem>
                    <FlexItem width="5vw"/>
                    <FlexItem width="5vw"/>
                    <FlexItem width="42.5vw">
                        <Flex justifyContent="center">
                            <Divider direction="horizontal" color={green} size="50%" margin={Size.LEVEL_FIVE}/>
                        </Flex>
                    </FlexItem>
                </Flex>
            </>
        }
        <Flex alignItems="flex-start">
            <FlexItem width="5vw">
                <Tag fontSize={Size.LEVEL_THREE}>{`S${index + 1}`}</Tag>
            </FlexItem>
            <FlexItem width="42.5vw">
                <Tag backgroundFlag={teamOneSubstitutes[index]?.country} clubLogo={teamOneSubstitutes[index]?.club}
                     boxShadow small star={teamOneSubstitutes[index]?.star}>
                    {teamOneSubstitutes[index] ? `${teamOneSubstitutes[index].firstname} ${teamOneSubstitutes[index].lastname.toUpperCase()}` : '--'}
                </Tag>
            </FlexItem>
            <FlexItem width="5vw"/>
            <FlexItem width="5vw"/>
            <FlexItem width="42.5vw">
                <Tag backgroundFlag={teamTwoSubstitutes[index]?.country} clubLogo={teamTwoSubstitutes[index]?.club}
                     boxShadow small clubLogoPosition='right' star={teamTwoSubstitutes[index]?.star}>
                    {teamTwoSubstitutes[index] ? `${teamTwoSubstitutes[index].firstname} ${teamTwoSubstitutes[index].lastname.toUpperCase()}` : '--'}
                </Tag>
            </FlexItem>
        </Flex>
    </div>;
};

const _generatePlayerTags = (match: TeamMatchProps) => {
    let playerTags: React.ReactElement[] = [];
    for (let i = 0; i < 4; i++) {
        if (match.all_single_matches[i]) {
            playerTags.push(_generateOnePlayerTag(match, i));
        }
    }
    for (let i = 0; i < 2; i++) {
        playerTags.push(_generateSubstitutes(match, i));
    }
    return playerTags;
};

const ViewSingleTeamMatch = ({queryParams}: any) => {
    const [match, setMatch] = useState<TeamMatchProps>(teamEmptyMatch);
    const [allSingleMatchGoals, setAllSingleMatchGoals] = useState({teamOneGoals: 0, teamTwoGoals: 0});
    const [readyToUpdate, setReadyToUpdate] = useState<boolean>(true);

    useEffect(() => {
        socket.on('updated-scores', (readyToUpdate, callback) => {
            setReadyToUpdate(readyToUpdate);
        });

        socket.on('updated-current-round', (readyToUpdate, callback) => {
            setReadyToUpdate(readyToUpdate);
        });
    }, []);

    useEffect(() => {
        fetchAPI(`/matches/Major2024/team/rounds`)
            .then(matches => {
                if (Object.keys(queryParams).length && Object.keys(matches).length) {
                    let reducedMatch: TeamMatchProps = matches.filter((match: TeamMatchProps) => match.table_number >= queryParams.from && match.table_number <= queryParams.to)[0];
                    if (reducedMatch && reducedMatch.all_single_matches) {
                        reducedMatch.all_single_matches = _sortMatchesByTableNumber(reducedMatch.all_single_matches);
                        setMatch(reducedMatch);
                    }
                } else {
                    setMatch(teamEmptyMatch);
                }
                setReadyToUpdate(false);
            });
    }, [readyToUpdate, queryParams]);

    useEffect(() => {
        setAllSingleMatchGoals(_computeSingleMatchGoals(match.all_single_matches));
    }, [match]);

    return (
        <Flex>
            <Container padding={Size.LEVEL_ZERO}>
                {
                    match && match.id >= 0 ?
                        <>
                            <Flex alignItems="flex-start">
                                <FlexItem width="5vw">
                                    <Flex justifyContent="center">
                                        <img src={ftcissy} width="80px" alt="Logo Issy"/>
                                    </Flex>
                                </FlexItem>
                                <FlexItem width="42.5vw">
                                    <Tag fontSize={Size.LEVEL_SIX} backgroundFlag={match.team_one.country} small>
                                        {match.team_one.name}
                                    </Tag>
                                </FlexItem>
                                <FlexItem width="5vw">
                                    <Tag fontSize={Size.LEVEL_SIX}
                                         borderColor={match.score_team_one > match.score_team_two ? green : (allSingleMatchGoals.teamOneGoals > allSingleMatchGoals.teamTwoGoals) ? green : 'transparent'}>
                                        {match.score_team_one}
                                    </Tag>
                                    <Spacer size={Size.LEVEL_TWO}/>
                                    <p style={{textAlign: 'center', fontSize: '20px', fontWeight: 'bold'}}>
                                        {allSingleMatchGoals.teamOneGoals}
                                    </p>
                                </FlexItem>
                                <FlexItem width="5vw">
                                    <Tag fontSize={Size.LEVEL_SIX}
                                         borderColor={match.score_team_one < match.score_team_two ? green : (allSingleMatchGoals.teamOneGoals < allSingleMatchGoals.teamTwoGoals) ? green : 'transparent'}>
                                        {match.score_team_two}
                                    </Tag>
                                    <Spacer size={Size.LEVEL_TWO}/>
                                    <p style={{textAlign: 'center', fontSize: '20px', fontWeight: 'bold'}}>
                                        {allSingleMatchGoals.teamTwoGoals}
                                    </p>
                                </FlexItem>
                                <FlexItem width="42.5vw">
                                    <Tag fontSize={Size.LEVEL_SIX} backgroundFlag={match.team_two.country} small>
                                        {match.team_two.name}
                                    </Tag>
                                </FlexItem>
                            </Flex>

                            <Spacer size={Size.LEVEL_THREE}/>

                            {
                                _generatePlayerTags(match)
                            }
                        </>
                        : <Title>No match found</Title>
                }
            </Container>
        </Flex>
    );
};

export default ViewSingleTeamMatch;
