import React, {useState} from 'react';
import Flex, {FlexItem} from "../components/Flex";
import Title from "../components/Title";
import Button from "../components/Button";
import Select from "../components/select/Select";
import Option from "../components/select/Option";
import Container from "../components/Container";

const Setup = () => {
    const [mode, setMode] = useState<'view' | 'update'>('view');
    const [type, setType] = useState<'individual' | 'team'>('individual');


    const generateButtons = (numberTables: number = 48) => {
        const buttons = [];
        for (let min = 1; min <= numberTables; min += 4) {
            buttons.push(
                <FlexItem key={min}>
                    <Button
                        width="200px"
                        onClick={() => window.location.href = `/match/${type}/${mode}?from=${min}&to=${min + 3}`}>
                        {min} - {min + 3}
                    </Button>
                </FlexItem>
            );
        }
        return buttons;
    }

    return <Container>
        <Flex>
            <FlexItem>
                <Title>View or Update ?</Title>
                <Select onChange={(event: any) => setMode(event.target.value.toLowerCase())}>
                    <Option>View</Option>
                    <Option>Update</Option>
                </Select>
            </FlexItem>
            <FlexItem>
                <Title>Individual or Team ?</Title>
                <Select onChange={(event: any) => setType(event.target.value.toLowerCase())}>
                    <Option>Individual</Option>
                    <Option>Team</Option>
                </Select>
            </FlexItem>
        </Flex>
        <Flex multiline>
            {generateButtons()}
        </Flex>
    </Container>
}

export default Setup;