import React, {useEffect, useState} from 'react';
import Container from "../../../components/Container";
import Flex, {FlexItem} from "../../../components/Flex";
import Tag from "../../../components/Tag";
import {Size} from "../../../props/common";
import Spacer from "../../../components/Spacer";
import Button from "../../../components/Button";
import {green, grey30, yellow} from "../../../config/style";
import {fetchAPI} from "../../../config/fetch";
import {_sortMatchesByTableNumber, MatchProps} from "../matchProps";
import io, {Socket} from "socket.io-client";
import {DefaultEventsMap} from "@socket.io/component-emitter";
import env from "../../../config/env";
import Swal from "sweetalert2";
import Divider from "../../../components/Divider";

let socket: Socket<DefaultEventsMap, DefaultEventsMap> = io(env.SOCKET, {transports: ['websocket']});
const UpdateMatch = () => {
    const [matches, setMatches] = useState<Array<MatchProps>>([]);
    const [matchToUpdate, setMatchToUpdate] = useState<any>({});
    const [readyToUpdate, setReadyToUpdate] = useState<boolean>(true);
    const [queryParams, setQueryParams] = useState<any>({});
    const [disableButtons, setDisableButtons] = useState<boolean>(false);

    const _freezeScores = (match: MatchProps) => {
        Swal.fire({
            title: 'Do you really want to freeze scores ?',
            showCancelButton: true,
            confirmButtonText: 'Yes',
        }).then((result) => {
            if (result.isConfirmed) {
                fetchAPI(`/matches/individual/update-frozen`, "POST", {...match, is_frozen: true})
                    .then(res => {
                        if (res.errMsg) alert(res.errMsg);
                        setReadyToUpdate(true);
                        Swal.fire({
                            position: 'center',
                            icon: 'success',
                            title: 'Thank you !',
                            showConfirmButton: false,
                            timer: 3000
                        });
                    })
                    .catch(err => {
                        alert(`An error occurred when updating score => ${err}"`);
                    });
            }
        });
    };

    useEffect(() => {
        setQueryParams(Object.fromEntries(new URLSearchParams(window.location.search).entries()));
    }, []);

    useEffect(() => {
        socket.on('updated-current-round', (readyToUpdate, callback) => {
            setReadyToUpdate(readyToUpdate);
        });
    }, []);

    useEffect(() => {
        fetchAPI(`/matches/Major2024/individual/rounds`)
            .then(res => {
                if (Object.keys(queryParams).length) {
                    setMatches(_sortMatchesByTableNumber(res.filter((match: MatchProps) => match.table_number >= queryParams.from && match.table_number <= queryParams.to)));
                } else {
                    setMatches(_sortMatchesByTableNumber(res));
                }
                setReadyToUpdate(false);
            });
    }, [readyToUpdate, queryParams]);

    const _setDelay = () => {
        setDisableButtons(true);
        setTimeout(() => {
            setDisableButtons(false);
        }, 500);
    };

    const updateScore = (newScore: number, idMatch: number, player: string) => {
        let matchesTmp = [...matches];
        // @ts-ignore
        matchesTmp[idMatch][player] = newScore >= 0 ? newScore : 0;
        setMatchToUpdate(matchesTmp[idMatch]);
        setMatches(matchesTmp);
        _setDelay();
    };

    const _playerLastnameUppercase = (playerName: string): string => {
        const playerNameSplitted: string[] = playerName.split(' ');
        const playerLastnameUppercased = playerNameSplitted[playerNameSplitted.length - 1].toUpperCase();
        playerNameSplitted.pop();
        return `${playerNameSplitted.join(' ')} ${playerLastnameUppercased}`;
    };

    useEffect(() => {
        if (Object.keys(matchToUpdate).length > 0) {
            setMatchToUpdate({});
            fetchAPI(`/matches/individual/update-scores/${matchToUpdate.id}`, "POST", matchToUpdate)
                .then(res => {
                    if (res.errMsg) alert(res.errMsg);
                    socket.emit('update-score', matchToUpdate);
                })
                .catch(err => {
                    alert(`An error occurred when updating score => ${err}"`);
                });
        }
    }, [matchToUpdate]);

    return (
        <Flex>
            <Container padding={Size.LEVEL_ZERO}>
                {matches.map(((match: MatchProps, index: number) => {
                    return <div key={index}>
                        {
                            index > 0 && <>
                                <Spacer size={Size.LEVEL_TWO}/>
                                <hr style={{borderWidth: 1, borderStyle: 'solid', borderColor: grey30}}/>
                                <Spacer size={Size.LEVEL_TWO}/>
                            </>
                        }
                        <Flex alignItems="flex-end" key={match.id}>
                            <FlexItem width="2.5vw" minWidth="0" marginTop={Size.LEVEL_ZERO}>
                                <p style={{fontSize: "24px"}}>{match.table_number}.</p>
                            </FlexItem>
                            <FlexItem width="42.5vw" marginTop={Size.LEVEL_ZERO}>
                                <Flex>
                                    <Button borderRadius={`${Size.LEVEL_ONE}px 0 0 ${Size.LEVEL_ONE}px`}
                                            height={`${Size.LEVEL_TEN}px`}
                                            onClick={() => updateScore(match.score_player_one - 1, index, "score_player_one")}
                                            disabled={match.is_frozen || disableButtons}>-</Button>
                                    <Tag borderRadius="0">{_playerLastnameUppercase(match.player_one.fullname)}</Tag>
                                    <Button color={green}
                                            borderRadius={`0 ${Size.LEVEL_ONE}px ${Size.LEVEL_ONE}px 0`}
                                            height={`${Size.LEVEL_TEN}px`}
                                            onClick={() => updateScore(match.score_player_one + 1, index, "score_player_one")}
                                            disabled={match.is_frozen || disableButtons}>+</Button>
                                </Flex>
                            </FlexItem>
                            <div style={{width: "12.5vw", marginRight: "16px", textAlign: "center"}}>
                                <Button color={yellow}
                                        fontSize={`13px`}
                                        disabled={match.is_frozen}
                                        onClick={() => _freezeScores(match)}
                                        width="100%" borderRadius="8px 8px 0 0"
                                        height={`${Size.LEVEL_FIVE}px`}>
                                    VALIDATE ✓
                                </Button>
                                <Flex>
                                    <FlexItem width="100%" marginTop={Size.LEVEL_ZERO}>
                                        <Tag borderRadius="0 0 8px 8px">
                                            {match.score_player_one}
                                            <Divider direction="vertical" margin={Size.LEVEL_TWO}/>
                                            {match.score_player_two}
                                        </Tag>
                                    </FlexItem>
                                </Flex>
                            </div>
                            <FlexItem width="42.5vw" marginTop={Size.LEVEL_ZERO}>
                                <Flex>
                                    <Button color={green}
                                            borderRadius={`${Size.LEVEL_ONE}px 0 0 ${Size.LEVEL_ONE}px`}
                                            height={`${Size.LEVEL_TEN}px`}
                                            onClick={() => updateScore(match.score_player_two + 1, index, "score_player_two")}
                                            disabled={match.is_frozen || disableButtons}>+</Button>

                                    <Tag borderRadius="0">{_playerLastnameUppercase(match.player_two.fullname)}</Tag>
                                    <Button borderRadius={`0 ${Size.LEVEL_ONE}px ${Size.LEVEL_ONE}px 0`}
                                            height={`${Size.LEVEL_TEN}px`}
                                            onClick={() => updateScore(match.score_player_two - 1, index, "score_player_two")}
                                            disabled={match.is_frozen || disableButtons}>-</Button>
                                </Flex>
                            </FlexItem>
                        </Flex>
                    </div>;
                }))}
            </Container>
        </Flex>
    );
};

export default UpdateMatch;
