import React, {useEffect, useState} from 'react';
import Container from "../components/Container";
import Title from "../components/Title";
import {fetchAPI} from "../config/fetch";
import {_sortMatchesByTableNumber, MatchProps} from "./match/matchProps";
import Table from "../components/table/Table";
import TableHead from "../components/table/TableHead";
import TableBody from "../components/table/TableBody";
import Flex from "../components/Flex";
import Button from "../components/Button";
import {Size} from "../props/common";
import Spacer from "../components/Spacer";
import io, {Socket} from "socket.io-client";
import {DefaultEventsMap} from "@socket.io/component-emitter";
import env from "../config/env";
import DashboardLayout from "../components/DashboardLayout";
import Input from "../components/Input";

let socket: Socket<DefaultEventsMap, DefaultEventsMap> = io(env.SOCKET, {transports: ['websocket']});
const Dashboard = () => {
    const [currentRound, setCurrentRound] = useState(1);
    const [updatedRound, setUpdatedRound] = useState(currentRound);
    const [matches, setMatches] = useState([]);
    const [readyToUpdate, setReadyToUpdate] = useState<boolean>(true);

    useEffect(() => {
        socket.on('updated-scores', (readyToUpdate, callback) => {
            setReadyToUpdate(readyToUpdate);
        });

        fetchAPI(`/current-round/Major2024`)
            .then(res => {
                setCurrentRound(res.current_round);
            });
    }, []);

    useEffect(() => {
        fetchAPI(`/matches/Major2024/individual/rounds`)
            .then(res => {
                // @ts-ignore
                setMatches(_sortMatchesByTableNumber(res));
                setReadyToUpdate(false);
            });
    }, [readyToUpdate, currentRound]);

    const _updateCurrentRound = () => {
        setCurrentRound(updatedRound);

        fetchAPI(`/current-round/Major2024`, 'POST', {current_round: updatedRound})
            .catch(err => {
                console.log(err.errMsg);
                alert('Error while updating round');
            });
        socket.emit('update-current-round', updatedRound);
    };

    return (
        <DashboardLayout>
            <Container padding={Size.LEVEL_THREE}>
                <Title>Current round : {currentRound}</Title>
                <Flex alignItems="center" justifyContent="space-between">
                    <Input
                        placeholder="Update round..."
                        onChange={(event) => setUpdatedRound(event.target.value)}
                        marginLess
                    />
                    <Button onClick={_updateCurrentRound}>{`🠞`}</Button>
                </Flex>
                <Spacer/>
                {
                    matches.length ?
                        <Table>
                            <TableHead>
                                <tr>
                                    <td width="10%">Table</td>
                                    <td width="25%">Player 1</td>
                                    <td width="15%">Score</td>
                                    <td width="25%">Player 2</td>
                                    <td width="25%">Referee</td>
                                </tr>
                            </TableHead>
                            <TableBody>
                                {
                                    matches.map((match: MatchProps, index) => {
                                        return <tr key={index}>
                                            <td>{match.table_number}</td>
                                            <td>{match.player_one.fullname}</td>
                                            <td>{match.score_player_one} - {match.score_player_two}</td>
                                            <td>{match.player_two.fullname}</td>
                                            <td>{match.referee}</td>
                                        </tr>;
                                    })
                                }
                            </TableBody>
                        </Table>
                        : <Title>No matches found for round {currentRound}</Title>
                }
            </Container>
        </DashboardLayout>
    );
};

export default Dashboard;
