import React, {useEffect, useState} from 'react';
import Container from "../../../components/Container";
import Flex, {FlexItem} from "../../../components/Flex";
import Tag from "../../../components/Tag";
import {Size} from "../../../props/common";
import Spacer from "../../../components/Spacer";
import io, {Socket} from "socket.io-client";
import {DefaultEventsMap} from "@socket.io/component-emitter";
import env from "../../../config/env";
import {_sortMatchesByTableNumber, MatchProps} from "../matchProps";
import {fetchAPI} from "../../../config/fetch";
import Divider from "../../../components/Divider";
import {green} from "../../../config/style";
import logoIssy from "../../../../src/assets/FTC_issy_Rond.png";
import Title from "../../../components/Title";

let socket: Socket<DefaultEventsMap, DefaultEventsMap> = io(env.SOCKET, {transports: ['websocket']});
const ViewMatch = () => {
    const [matches, setMatches] = useState<Array<MatchProps>>([]);
    const [readyToUpdate, setReadyToUpdate] = useState<boolean>(true);
    const [queryParams, setQueryParams] = useState<any>({});

    useEffect(() => {
        setQueryParams(Object.fromEntries(new URLSearchParams(window.location.search).entries()));
    }, []);

    useEffect(() => {
        socket.on('updated-scores', (readyToUpdate, callback) => {
            setReadyToUpdate(readyToUpdate);
        });

        socket.on('updated-current-round', (readyToUpdate, callback) => {
            setReadyToUpdate(readyToUpdate);
        });
    }, []);

    useEffect(() => {
        fetchAPI(`/matches/Major2024/individual/rounds`)
            .then(res => {
                if (Object.keys(queryParams).length) {
                    setMatches(_sortMatchesByTableNumber(res.filter((match: MatchProps) => match.table_number >= queryParams.from && match.table_number <= queryParams.to)));
                } else {
                    setMatches(_sortMatchesByTableNumber(res));
                }
                setReadyToUpdate(false);
            });
    }, [readyToUpdate, queryParams]);

    return (
        <Container padding={Size.LEVEL_FOUR}>
            <img src={logoIssy} style={{position: "fixed", right: 56}} width="120px" alt="Logo Issy"/>
            <Flex marginBottom={`${Size.LEVEL_FOUR}px`} justifyContent="center">
                <Title size={Size.LEVEL_SEVEN}>MAJOR OF PARIS 2024</Title>
            </Flex>
            <Spacer/>
            <Flex>
                <Container padding={Size.LEVEL_ZERO}>
                    {matches.map(((match: MatchProps, index: number) => {
                        console.log(match.player_one)
                        return <div key={match.id}>
                            {
                                index !== 0 &&
                                <Divider direction="horizontal" color={green} size="100%" margin={Size.LEVEL_FOUR}/>
                            }

                            <div style={{position: "relative"}}>
                                <div style={{position: "absolute", width: "60px"}}>
                                    <Tag fontSize={Size.LEVEL_THREE} height={Size.LEVEL_SIX}>
                                        {match.table_number}
                                    </Tag>
                                </div>
                                <Flex justifyContent="center">
                                    <FlexItem width="350px" marginTop={0}>
                                        <Tag fontSize={Size.LEVEL_THREE} height={Size.LEVEL_SIX}>
                                            {match.referee}
                                        </Tag>
                                    </FlexItem>
                                </Flex>
                                <Spacer size={Size.LEVEL_TWO}/>
                                <Flex alignItems="flex-start">
                                    <FlexItem width="45vw" marginTop={Size.LEVEL_ZERO}>
                                        <Tag backgroundFlag={match.player_one.country} clubLogo={match.player_one.club}
                                             boxShadow height={Size.LEVEL_THIRTEEN} star={match.player_one.star}>
                                            {match.player_one.fullname}
                                        </Tag>
                                    </FlexItem>
                                    <FlexItem width="5vw" marginTop={Size.LEVEL_ZERO}>
                                        <Tag boxShadow height={Size.LEVEL_THIRTEEN} fontSize={Size.LEVEL_FIVE}>
                                            {match.score_player_one}
                                        </Tag>
                                    </FlexItem>
                                    <FlexItem width="5vw" marginTop={Size.LEVEL_ZERO}>
                                        <Tag boxShadow height={Size.LEVEL_THIRTEEN} fontSize={Size.LEVEL_FIVE}>
                                            {match.score_player_two}
                                        </Tag>
                                    </FlexItem>
                                    <FlexItem width="45vw" marginTop={Size.LEVEL_ZERO}>
                                        <Tag backgroundFlag={match.player_two.country} clubLogo={match.player_two.club}
                                             clubLogoPosition="right" boxShadow height={Size.LEVEL_THIRTEEN}
                                             star={match.player_two.star}>
                                            {match.player_two.fullname}
                                        </Tag>
                                    </FlexItem>
                                </Flex>
                            </div>
                        </div>;
                    }))}
                </Container>
            </Flex>
        </Container>
    );
};

export default ViewMatch;
