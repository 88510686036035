import React from 'react';
import styled from 'styled-components';
import Sidebar from "./Sidebar";

interface DashboardLayoutProps {
    children?: any
    currentPage?: 'home' | 'groups' | 'results' | 'rounds'
    sidebar?: boolean
}

const DashboardLayoutStyled = styled.div<DashboardLayoutProps>`
  margin-left: ${props => props.sidebar ? '250px' : '0' };
  
  @media screen and (max-width: 1024px) {
    margin-left: 0;
  }
`;

export const DashboardLayout = ({children, currentPage = 'home', sidebar = true}: DashboardLayoutProps) => {
    return <DashboardLayoutStyled sidebar={sidebar}>
        {
            sidebar && <Sidebar currentTab={currentPage}/>
        }
        {children}
    </DashboardLayoutStyled>
}

export default DashboardLayout