import React from 'react';
import styled from 'styled-components';
import Title from "./Title";
import Flex from "./Flex";
import {grey150, red} from "../config/style";
import Spacer from "./Spacer";
import {Size} from "../props/common";
import {Link} from "react-router-dom";

interface SidebarProps {
    currentTab?: string
}

const SidebarStyled = styled.div`
  background-color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 250px;
  box-shadow: 0 0 5px 0 #EBEBEB;

  @media screen and (max-width: 1024px) {
    display: none;
  }

  p {
    color: ${grey150};
  }

  a {
    width: 100%;
    padding: 8px 32px;
    margin: 8px 0;
    text-decoration: none;
    color: black;
    display: flex;
    align-items: center;
    font-size: 16px;

    &:hover,
    &.is-active {
      font-weight: bold;
      border-right: 2px solid ${red};
      color: ${red};
    }

    img {
      margin-right: 24px;
    }
  }
`;

export const Sidebar = ({currentTab = "home"}: SidebarProps) => {
    return <SidebarStyled>
        <Spacer size={Size.LEVEL_TWO}/>
        <Title fontFamily="Fredoka_OneFont" size={Size.LEVEL_FIVE} centered>
            skore.
        </Title>
        <Spacer size={Size.LEVEL_TWO}/>
        <Flex flexDirection="column" alignItems="flex-start">
            <Link to='/dashboard' className={currentTab === "home" ? 'is-active' : ''}>
                <img src={require("../assets/icons/home.png")} width="24px" alt="Home"/>
                Home
            </Link>
            <Link to='/groups' className={currentTab === "groups" ? 'is-active' : ''}>
                <img src={require("../assets/icons/group.png")} width="24px" alt="Group"/>
                Groups
            </Link>
            <Link to='/results' className={currentTab === "results" ? 'is-active' : ''}>
                <img src={require("../assets/icons/ranking.png")} width="24px" alt="Ranking"/>
                Results
            </Link>
            <Link to='/rounds' className={currentTab === "rounds" ? 'is-active' : ''}>
                <img src={require("../assets/icons/round.png")} width="24px" alt="Ranking"/>
                Rounds
            </Link>
        </Flex>
    </SidebarStyled>;
}

export default Sidebar
