import React from 'react';
import styled from 'styled-components';
import {Size} from "../props/common";

interface SpacerProps {
    size?: Size
    horizontal?: boolean
}

const SpacerStyled = styled.div<SpacerProps>`
  width: ${props => props.horizontal ? `${props.size}px` : `0`};
  height: ${props => props.horizontal ? `0` : `${props.size}px`};
`;

export const Spacer = ({size = Size.LEVEL_THREE, horizontal = false}: SpacerProps) => {
    return <SpacerStyled size={size} horizontal={horizontal} />
}

export default Spacer
