import React from 'react';
import styled from 'styled-components';
import {lightRed, red} from "../config/style";
import {Size} from "../props/common";

interface InputTextProps {
    placeholder?: string;
    defaultValue?: string;
    onChange?: (event: any) => void;
    marginLess?: boolean;
}

interface InputFileProps {
    onChange?: any
}

const InputTextStyled = styled.input<InputTextProps>`
  background-color: ${lightRed};
  border-radius: 8px;
  padding: ${Size.LEVEL_THREE}px;
  margin-bottom: ${props => props.marginLess ? '0' : `${Size.LEVEL_TWO}`}px;
  width: 100%;
  height: ${Size.LEVEL_FIVE}px;
  box-sizing: border-box;
  color: ${red};
  font-weight: bold;
  outline-color: ${red};

  &::placeholder {
    color: ${red};
  }
`;

const InputFileStyled = styled.input`
`;

export const InputText = ({placeholder = "", defaultValue = "", onChange, marginLess}: InputTextProps) => {
    return <InputTextStyled
        placeholder={placeholder}
        type="text"
        defaultValue={defaultValue}
        onChange={onChange}
        marginLess={marginLess}
    />;
}

export const InputFile = ({onChange}: InputFileProps) => {
    return <InputFileStyled type="file" onChange={onChange}/>;
}

export const InputDate = () => {
    return;
}

export const InputNumber = () => {
    return;
}

export default InputText
