import React from 'react';
import styled from 'styled-components';
import {AccordionHeaderProps} from "./AccordionHeader";
import {AccordionBodyProps} from "./AccordionBody";
import {grey50, red} from "../../config/style";

export interface AccordionItemProps {
    children: React.ReactElement<AccordionHeaderProps & AccordionBodyProps>[]
    className?: any
}

const AccordionItemStyled = styled.div<AccordionItemProps>`
  border: 1px solid ${grey50};
  border-radius: 8px;
  
  &:not(:last-child) {
    margin-bottom: 8px;
  }
  
  &:hover,
  &:focus,
  &.is-active {
    border: 2px solid ${red};

    [data-accordion-header] {
      padding: 15px;
      color: ${red};
      
      &:after {
        right: 15px;
      }
    }
  }

  &.is-active {
    [data-accordion-header]:after {
      content: "ᐁ";
    }

    [data-accordion-body] {
      display: block;
    }
  }
`;

const toggleAccordionItem = (event: any) => {
    const header = event.target;
    const item = header?.parentNode;
    const accordion = item?.parentNode;
    const accordionItems = accordion.querySelectorAll('[data-accordion-item]');

    accordionItems.forEach((accordionItem: HTMLElement, index: number) => {
        accordionItem !== item && accordionItem.classList.remove('is-active');
    });

    item.classList.toggle('is-active');
}

export const AccordionItem = ({children, className}: AccordionItemProps) => {
    return <AccordionItemStyled data-accordion-item className={className}>
        {
            children.map((child, index) => {
                return React.cloneElement(child, {
                    key: index,
                    onClick: index === 0 ? (event: any) => toggleAccordionItem(event) : null
                });
            })
        }
    </AccordionItemStyled>
}

export default AccordionItem
