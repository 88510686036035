import React from 'react';
import styled from 'styled-components';
import {Size} from "../props/common";

interface FlexProps {
    children: any
    justifyContent?: "flex-start" | "flex-end" | "center" | "space-between" | "space-around" | "space-evenly" | "initial" | "inherit"
    alignItems?: "stretch" | "center" | "flex-start" | "flex-end" | "baseline" | "initial" | "inherit"
    flexDirection?: "row" | "row-reverse" | "column" | "column-reverse" | "initial" | "inherit"
    height?: string
    marginTop?: string
    marginBottom?: string
    multiline?: boolean
}

interface FlexItemProps {
    children?: any
    width?: string
    minWidth?: string
    marginTop?: Size
}

const FlexStyled = styled.div<FlexProps>`
  display: flex;
  justify-content: ${props => props.justifyContent};
  align-items: ${props => props.alignItems};
  flex-direction: ${props => props.flexDirection};
  width: 100%;
  height: ${props => props.height};
  box-sizing: border-box;
  flex-wrap: ${props => props.multiline ? 'wrap' : 'no-wrap'};

  @media screen and (max-width: 768px) {
    flex-wrap: wrap;
  }
`;

const FlexItemStyled = styled.div<FlexItemProps>`
  width: ${props => props.width};
  min-width: ${props => props.minWidth};
  margin-top: ${props => props.marginTop}px;
  margin-right: ${Size.LEVEL_TWO}px;

  &:last-child {
    margin-right: 0;
  }

  @media screen and (max-width: 768px) {
    min-width: unset;
    margin-right: 0;
    width: 100%;
  }
`;

export const Flex = ({
                         children,
                         justifyContent = "center",
                         alignItems = "center",
                         flexDirection = "row",
                         height = "auto",
                         marginTop = "0",
                         marginBottom = "0",
                         multiline = false
                     }: FlexProps) => {
    return <FlexStyled justifyContent={justifyContent} alignItems={alignItems} flexDirection={flexDirection}
                       height={height} marginTop={marginTop} marginBottom={marginBottom} multiline={multiline}>
        {children}
    </FlexStyled>
}

export const FlexItem = ({
                             children,
                             width = "100%",
                             minWidth = `${Size.LEVEL_TWELVE}px`,
                             marginTop = Size.LEVEL_TWO
                         }: FlexItemProps) => {
    return <FlexItemStyled width={width} minWidth={minWidth} marginTop={marginTop}>
        {children}
    </FlexItemStyled>
}

export default Flex
